<template>
  <div class="answer">
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="width_1200">
      <div class="cont">
        <div class="top" v-if="mobile != true">
          <div class="title" @click="quitPractice()">
            <i class="el-icon-arrow-left"></i>
            退出练习
          </div>
        </div>
        <div class="list">
          <div class="li" v-for="(li, i) in 1" :key="i">
            <div class="title">
              <p class="tmtype"><span>判断题</span><span>题目1：</span></p>
              <p class="tmmc">山东山西的“山”指的是？</p>
            </div>
            <el-radio-group v-model="radio" @change="handChange()">
              <p>
                <el-radio :label="3">备选项</el-radio>
              </p>
              <p>
                <el-radio :label="6">备选项</el-radio>
              </p>
              <p>
                <el-radio :label="9">备选项</el-radio>
              </p>
              <p>
                <el-radio :label="12">备选项</el-radio>
              </p>
            </el-radio-group>
          </div>
        </div>
        <el-row>
          <el-col :span="8">
            <i class="el-icon-arrow-left"></i>
            上一题
          </el-col>
          <el-col :span="8">
            <img src="../../assets/images/lamp.svg" width="16" />
            查看答案
          </el-col>
          <el-col :span="8">
            上一题
            <i class="el-icon-arrow-right"></i>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";

export default {
  data() {
    return {
      answer: {},
      radio: "",
      mobile:false,
    };
  },
  components: {
    BreadCrumb,
  },
  created() {
     let sUserAgent = navigator.userAgent.toLowerCase();
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
      //跳转移动端页面
        this.mobile = true;
      } else {
      //跳转pc端页面
        this.mobile = false;
      }
    this.init();
  },
  mounted() {},
  methods: {
    handChange() {},
    quitPractice(){
      this.$router.push({ path: "/onlineQuestionBank/details" });
    },
    init() {
      this.answer = this.$route.query;
      console.log(this.answer, "");
    },
  },
};
</script>

<style lang="less" scoped>
.answer {
  // margin-top: -30px;
  background-color: #f5f5f5;
  font-size: 14px;
  .cont {
    .top {
      background-color: #fff;
      margin-bottom: 10px;
      .title {
        line-height: 100%;
        padding: 20px 0;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        i {
          font-weight: bold;
        }
      }
    }
    .list {
      min-height: 350px;
      margin-bottom: 15px;
      background-color: #fff;
      .li {
        margin-bottom: 5px;
        padding: 20px 30px;
        background-color: #fff;
        .title {
          margin-bottom: 30px;
          p {
            line-height: 22px;
            height: 22px;
          }
          .tmtype {
            span {
              &:first-child {
                display: inline-block;
                background-color: #56b155;
                color: #fff;
                padding: 0 8px;
                border-radius: 20px;
                margin-right: 20px;
              }
            }
          }
          .tmmc {
            margin-top: 10px;
          }
        }
        .el-radio-group {
          p {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .el-row {
      background-color: #fff;
      text-align: center;
      padding: 10px 0;
      font-size: 16px;
      margin-bottom: 20px;
      .el-col {
        cursor: pointer;
        img {
          width: 20px;
          vertical-align: text-bottom;
        }
      }
    }
  }
}
</style>